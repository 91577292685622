import { createSlice, original } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { mapOverItems } from './treeDataUtils';
import type { RootState } from '..';
import type { TenderItem } from '../../interfaces';

const initialState: {
	tenderItems: {
		data: { [key: string]: any };
		parentlessIds: string[];
		treeData: TenderItem[];
		totalItems: number;
		totalCheckedItems: number;
		totalUncheckedItems: number;
	};
} = {
	tenderItems: {
		data: {},
		parentlessIds: [],
		totalCheckedItems: 0,
		totalItems: 0,
		totalUncheckedItems: 0,
		treeData: []
	}
};

const tenderItems = createSlice({
	initialState,
	name: 'tenderItems',
	reducers: {
		setTenderItems(state: any, action: PayloadAction<string>) {
			// eslint-disable-next-line no-param-reassign
			state.tenderItems = action.payload;
		},
		updateTenderItem(state: any, action: PayloadAction<any>) {
			const updated = action.payload;
			const tenderItems = original(state.tenderItems);
			const treeStructure = { ...tenderItems.data, [updated.tid]: updated };
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.data[updated.tid as string] = updated;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.treeData = mapOverItems(treeStructure, tenderItems.parentlessIds);
		},
		updateTenderItems(
			state: any,
			action: PayloadAction<{ treeStructure: { [key: string]: any } }>
		) {
			const { treeStructure } = action.payload;
			const tenderItems = original(state.tenderItems);
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.data = treeStructure;
			// eslint-disable-next-line no-param-reassign
			state.tenderItems.treeData = mapOverItems(treeStructure, tenderItems.parentlessIds);
		}
	}
});

export default tenderItems.reducer;
export const { setTenderItems, updateTenderItem, updateTenderItems } = tenderItems.actions;
export const selectTenderItems = (state: RootState) => state.tenderItems.tenderItems;
export const selectTenderItem = (tid: string) => (state: RootState) => {
	const { tenderItems } = state.tenderItems;
	return tenderItems.data[tid];
};
export const selectTenderItemProductPredictions = (tid: string) => (state: RootState) => {
	const { tenderItems } = state.tenderItems;
	const ids: string[] = [];
	const entities = tenderItems.data[tid]?.productPredictions?.reduce((acc: any, obj: any) => {
		ids.push(obj.id);
		acc[obj.id] = {
			...obj,
			isTenderGroup: false,
			parentItem: tid,
			product: true
		};
		return acc;
	}, {});

	return { entities, ids };
};

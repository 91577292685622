import { createApi } from '@reduxjs/toolkit/query/react';

import getArePositionsChecked from './utils';
import type {
	PollingDataType,
	TenderDocContentResponseType,
	TenderDocumentHeaderContentType
} from '../../types/TenderDocument.types';
import type { GaebType } from '../../types/TenderDocumentType.types';
import { baseQuery, TENDER_DOC_URL, TENDER_MANAGEMENT_URL } from '../stateUtils';
import { setTenderItems } from '../tenderItems/tenderItemsState';
import { setSuppliers } from '../tenderItems/tenderItemsSuppliersState';
import { createInitialTreeStructure, mapOverItems } from '../tenderItems/treeDataUtils';
import getInitialTree from '../tenderItems/utils';
import { toggleShouldOpenBlockerModal } from '../ui/uiSlice';

const tenderDocContentApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			deleteTenderDocContentAttachment: builder.query<string, string>({
				query(id) {
					return {
						method: 'DELETE',
						url: `${TENDER_MANAGEMENT_URL}document-responses/${id}`
					};
				}
			}),
			fetchGaeb: builder.query<GaebType, { contentUUID: string; sgArea: string }>({
				async onQueryStarted(args, { dispatch, queryFulfilled }) {
					const response = await queryFulfilled;
					getInitialTree({
						akt: args.sgArea,
						award: response.data.award.tid,
						contentUUID: args.contentUUID
					}).then((response) => {
						setTimeout(() => {
							const { data, totalItems } = response;
							const { parentlessIds, treeStructure } = createInitialTreeStructure(data);
							const { totalCheckedItems, totalUncheckedItems } = getArePositionsChecked(data);

							const normalizedData = {
								data: treeStructure,
								parentlessIds,
								status: 'DONE',
								totalCheckedItems,
								totalItems,
								totalUncheckedItems,
								treeData: mapOverItems(treeStructure, parentlessIds)
							};

							dispatch(setSuppliers(normalizedData.data));
							dispatch(setTenderItems(normalizedData as any));
						}, 6000);
					});
				},
				query: ({ contentUUID }) => ({
					url: `${TENDER_DOC_URL}gaebs/${contentUUID}`
				})
			}),
			fetchTemplate: builder.query<string, string | null>({
				query: (id) => ({
					responseHandler: (response) => response.text(),
					url: `${TENDER_DOC_URL}gaebs/freemarker/${id}/default`
				})
			}),
			fetchTenderDocumentHeaderContent: builder.query<
				TenderDocumentHeaderContentType,
				string | null
			>({
				query: (id) => `${TENDER_DOC_URL}gaebs/header/data/${id}`
			}),
			pollingTenderDocStatus: builder.query<PollingDataType, string | null>({
				query: (id) => `${TENDER_MANAGEMENT_URL}document-responses/${id}`
			}),
			uploadTenderDocContent: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}file-upload`
					};
				}
			}),
			uploadTenderDocContentAttachments: builder.mutation<TenderDocContentResponseType, FormData>({
				async onQueryStarted(_, { dispatch }) {
					dispatch(toggleShouldOpenBlockerModal(true));
				},
				query(body) {
					return {
						body,
						method: 'POST',
						url: `${TENDER_MANAGEMENT_URL}attachment-upload`
					};
				}
			})
		};
	},
	reducerPath: 'tenderDocument'
});

export const {
	useFetchTenderDocumentHeaderContentQuery,
	useLazyFetchTemplateQuery,
	usePollingTenderDocStatusQuery,
	useUploadTenderDocContentMutation,
	useUploadTenderDocContentAttachmentsMutation,
	useLazyDeleteTenderDocContentAttachmentQuery,
	useFetchGaebQuery
} = tenderDocContentApi;

export const { select: selectTemplate } = tenderDocContentApi.endpoints.fetchTemplate;

export { tenderDocContentApi };

import type { UserRefinementPosition } from '../../interfaces';

function sortChildrenByPosition(node: any) {
	if (node.children?.length > 0) {
		node.children.sort((a: any, b: any) => {
			const positionA = a.position.split('.').map(Number);
			const positionB = b.position.split('.').map(Number);
			for (let i = 0; i < Math.min(positionA.length, positionB.length); i += 1) {
				if (positionA[i] !== positionB[i]) {
					return positionA[i] - positionB[i];
				}
			}
			return positionA.length - positionB.length;
		});
		node.children.forEach((child: any) => sortChildrenByPosition(child));
	}
}

export function sortTreeData(treeData: any[]) {
	const sortedTreeData = JSON.parse(JSON.stringify(treeData)) as any[]; // Deep copy the original treeData
	sortedTreeData.forEach((node: any) => sortChildrenByPosition(node));
	return sortedTreeData;
}

export const createInitialTreeStructure = (items: { entities: any; ids: string[] }) => {
	const parentlessIds: any = [];
	const treeStructure = items.ids.reduce(
		(previousValue: any, currentValue: any) => {
			const tenderItem = items.entities[currentValue];
			if (!tenderItem) {
				return previousValue;
			}
			const { parentItem } = tenderItem;
			const hasParent = !!parentItem;
			const { tid, isTenderGroup } = tenderItem;
			const { productAssigments, status } = items.entities[currentValue] as UserRefinementPosition;

			if (!hasParent && isTenderGroup) {
				// eslint-disable-next-line no-param-reassign
				previousValue[tid] = {
					...tenderItem,
					children: []
				};
				parentlessIds.push(tid);
			}
			if (hasParent && isTenderGroup) {
				const itemsParent = previousValue[parentItem as string];
				const newChildren = itemsParent.children;
				newChildren?.push(tid);
				// eslint-disable-next-line no-param-reassign
				previousValue[parentItem as string] = {
					...itemsParent,
					children: newChildren
				};
				// eslint-disable-next-line no-param-reassign
				previousValue[tid] = {
					...tenderItem,
					children: [],
					parentUUID: parentItem
				};
			}
			if (!isTenderGroup) {
				const itemsParent = previousValue[parentItem as string];
				const newChildren = itemsParent.children || [];
				newChildren?.push(tid);
				// eslint-disable-next-line no-param-reassign
				previousValue[parentItem as string] = {
					...itemsParent,
					children: newChildren
				};
				// eslint-disable-next-line no-param-reassign
				previousValue[tid] = {
					...tenderItem,
					parentUUID: parentItem,
					status,
					...(['CONFIRMED', 'IN_PROGRESS'].includes(status) &&
						!!productAssigments && { children: [] })
				};
			}
			return previousValue;
		},
		{} as Record<string, any>
	);

	return { parentlessIds, treeStructure };
};

const addChildren = (ids: string[], treeData: Record<string, any>): any[] =>
	ids.map((id) => ({
		...treeData[id],
		children: treeData[id].children
			? addChildren(treeData[id].children as string[], treeData)
			: undefined
	}));

export const mapOverItems = (treeStructuredData: Record<string, any>, parentlessIds: string[]) => {
	const items = parentlessIds.map((parentlessId) => ({
		...treeStructuredData[parentlessId],
		children: treeStructuredData[parentlessId].children
			? addChildren(treeStructuredData[parentlessId].children as string[], treeStructuredData)
			: undefined
	}));

	return items;
};

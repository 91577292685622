import { createApi } from '@reduxjs/toolkit/query/react';

import type { GenericObjectType } from '../../types';
import { setAiProducts } from '../search/searchAiPredictionsProducts2Slice';
import { baseQuery } from '../stateUtils';
import { updateTenderItems } from '../tenderItems/tenderItemsState';

const productMasterDataApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchHulkProductsByIds: builder.query<
				{ entities: { [key: string]: any }; ids: string[] },
				{ ids: string; tid: string | undefined; isAiSearch?: boolean }
			>({
				forceRefetch: () => true,
				keepUnusedDataFor: Infinity,
				async onQueryStarted({ tid, isAiSearch }, { queryFulfilled, getState, dispatch }) {
					try {
						const { data } = await queryFulfilled;
						// @ts-ignore
						const { tenderItems } = getState().tenderItems;
						const currentTenderItem = tenderItems.data[tid];
						const newChildren = data.ids.reduce((acc: any, id: any) => {
							const obj1 = data.entities[id];
							const matchingObj = currentTenderItem.productAssigments.find(
								(obj2: any) => obj2.productId === obj1.maraId
							);

							if (matchingObj) {
								acc.push({ ...obj1, ...matchingObj, unit: obj1.unitOfMeasure });
							}
							return acc;
						}, []);
						const currentChildren = currentTenderItem.children;
						const children = currentTenderItem.children
							? Array.from(
									new Set([...newChildren.map((child: any) => child.tid), ...currentChildren])
								)
							: newChildren.map((child: any) => child.tid);
						const newData = {
							...tenderItems.data,
							...newChildren.reduce((acc: any, obj: any) => {
								acc[obj.tid] = {
									...obj,
									isTenderGroup: false,
									parentItem: tid,
									product: true,
									ttItemId: obj.maraId
								};
								return acc;
							}, {}),
							[currentTenderItem.tid]: {
								...currentTenderItem,
								children
							}
						};
						dispatch(updateTenderItems({ treeStructure: newData }));
						if (isAiSearch) {
							dispatch(setAiProducts({ data }));
						}
					} catch {
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: ({ ids }) => `services/productmasterdata/api/hulk-products-by-ids?ids=${ids}`,
				transformResponse: (response: any) =>
					response.reduce(
						(acc: any, curr: any) => {
							acc.entities[curr.id as string] = curr;
							if (!acc.ids.includes(curr.id)) {
								acc.ids.push(curr.id);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<any>,
							ids: [] as string[]
						}
					)
			})
		};
	},
	reducerPath: 'productMasterData'
});

export const { useFetchHulkProductsByIdsQuery } = productMasterDataApi;
export const selectHulkProducts = productMasterDataApi.endpoints.fetchHulkProductsByIds.select;

export { productMasterDataApi };

import type { TFunction } from 'i18next';

const getRoutes = (t: TFunction) => ({
	CREATE_INQUIRE: t('routes.CREATE_INQUIRE'),
	EDIT_INQUIRE: t('routes.EDIT_INQUIRE'),
	ONE_PAGER_AI_ANALYSIS: t('routes.ONE_PAGER_AI_ANALYSIS'),
	ONE_PAGER_CUSTOMER: t('routes.ONE_PAGER_CUSTOMER'),
	ONE_PAGER_DATEIN: t('routes.ONE_PAGER_DATEIN'),
	ONE_PAGER_EXPORT_AN_SAP: t('routes.ONE_PAGER_EXPORT_AN_SAP'),
	ONE_PAGER_OVERVIEW: t('routes.ONE_PAGER_OVERVIEW'),
	ONE_PAGER_SHOPPING_CARTS: t('routes.ONE_PAGER_SHOPPING_CARTS'),
	ONE_PAGER_SUPPLIER_INQUIRIES: t('routes.ONE_PAGER_SUPPLIER_INQUIRIES'),
	ONE_PAGER_TENDER_ITEMS: t('routes.ONE_PAGER_TENDER_ITEMS'),
	PROJECTS: t('routes.PROJECTS'),
	PROJECTS_CREATE: t('routes.PROJECTS_CREATE')
});

export default getRoutes;

import { toast } from 'react-toastify';

// @ts-ignore
export const fetchData = async (endpoint: string, retries = 3) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}services/tenderdoccontent/api/${endpoint}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jhi-authenticationToken')}`
				}
			}
		);
		const data = await response.json();
		return data;
	} catch (error: any) {
		if (error.message === 'Failed to fetch' && retries > 0) {
			return fetchData(endpoint, retries - 1);
		}
		toast.error('Failed to fetch.', { toastId: 'TenderDocContentApi' });
		throw new Error('error');
	}
};

// @ts-ignore
export const fetchAiPredictionsData = async (endpoint: string, retries = 3) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}services/aipredictions2/api/${endpoint}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jhi-authenticationToken')}`
				}
			}
		);
		const data = await response.json();
		return data;
	} catch (error: any) {
		if (error.message === 'Failed to fetch' && retries > 0) {
			return fetchAiPredictionsData(endpoint, retries - 1);
		}
		toast.error('Failed to fetch.', { toastId: 'aipredictions2' });
		throw new Error('Failed to fetch');
	}
};

// @ts-ignore
export const fetchUserRefinementData = async (endpoint: string, retries = 3) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}services/userrefinement2/api/${endpoint}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jhi-authenticationToken')}`
				}
			}
		);
		const data = await response.json();
		return data;
	} catch (error: any) {
		if (error.message === 'Failed to fetch' && retries > 0) {
			return fetchUserRefinementData(endpoint, retries - 1);
		}
		toast.error('Failed to fetch.', { toastId: 'userrefinement2' });
		throw new Error('error');
	}
};

import { fetchAiPredictionsData, fetchData, fetchUserRefinementData } from './apiUtils';
import type { TenderItem } from '../../interfaces';
import type { UserItemRefinement } from '../../interfaces/UserRefinement2.types';
import type { AiPrediction2, ItemPrediction } from '../../types/AiPredictionsType.types';
import type {
	Award,
	BoQ,
	BoQBody,
	BoqCtgiesData,
	BoQCtgy,
	ItemList
} from '../../types/TenderDocumentType.types';

const getInitialTree = async ({
	akt,
	award,
	contentUUID
}: {
	akt: string;
	award: string;
	contentUUID: string;
}) => {
	const awards = (await fetchData(`awards/${award}`)) as Award;
	const boq = (await fetchData(`bill-of-quantities/${awards.boQ.tid}`)) as BoQ;
	const boqBody = (await fetchData(`bo-q-bodies/${boq.boQBody.tid}`)) as BoQBody;
	const aiPredictions = (await fetchAiPredictionsData(
		`predictions/ensure/for/${akt}/${contentUUID}?structureOnly=false`
	)) as AiPrediction2;
	const predictionId = aiPredictions.id;
	const getItems = async () => {
		const normalizedItems: any = { entities: {}, ids: [] };
		const sortedCtgies = boqBody.boQCtgies.sort(
			(a: any, b: any) => a.listOrderIndex - b.listOrderIndex
		);

		const refinementData = (await fetchUserRefinementData(
			`tender-refinements/ensure/for/${akt}/${contentUUID}`
		)) as any;
		sortedCtgies.forEach(async (ctgy) => {
			normalizedItems.ids.push(ctgy.tid);
			const boqCtgy = (await fetchData(`bo-q-ctgies/${ctgy.tid}`)) as BoqCtgiesData;
			const childBody = (await fetchData(`bo-q-bodies/${boqCtgy.childBoQBody.tid}`)) as BoQBody;

			const itemPrediction = (await fetchAiPredictionsData(
				`item-predictions/from/${predictionId}/${ctgy.tid}`
			)) as ItemPrediction;

			let productCategory = '';
			if (itemPrediction.productCategoryPredictions.length) {
				const response = (await fetchAiPredictionsData(
					`product-category-predictions/${itemPrediction.productCategoryPredictions[0].id}`
				)) as any;
				productCategory = response.categoryName;
			}

			if (!itemPrediction.tenderItemId) {
				normalizedItems.ids.pop();
				return;
			}
			const userRefinementData = (await fetchUserRefinementData(
				`item-refinements/from/${refinementData.tid}/${itemPrediction.tenderItemId}`
			)) as UserItemRefinement;

			const parentObj: TenderItem = {
				isOffer: false,
				isTenderGroup: true,
				itemPredictionId: itemPrediction.id,
				longText: itemPrediction.longText,
				numOfEiwa: 0,
				numOfHawa: 0,
				offer: false,
				parentItem: '',
				parentUUID: '',
				position: ctgy.rNoPart,
				probability: 1,
				productCategory,
				quantity: 1,
				refinementId: userRefinementData.tid,
				shortText: ctgy.lblTx,
				sorting: 0,
				status: userRefinementData?.status || 'OPEN',
				tid: ctgy.tid,
				unit: '',
				userItemRefinementCategories: [],
				userItemRefinementSuppliers: userRefinementData?.supplierAssigments,
				userRefinementProducts: []
			};
			normalizedItems.entities[parentObj.tid] = parentObj;
			childBody.boQCtgies
				.sort((a, b) => a.listOrderIndex - b.listOrderIndex)
				.forEach(async (child: BoQCtgy) => {
					normalizedItems.ids.push(child.tid);
					const itemPrediction = (await fetchAiPredictionsData(
						`item-predictions/from/${predictionId}/${child.tid}`
					)) as ItemPrediction;
					let productCategory = '';
					if (itemPrediction.productCategoryPredictions?.length) {
						const response = (await fetchAiPredictionsData(
							`product-category-predictions/${itemPrediction.productCategoryPredictions[0].id}`
						)) as any;
						productCategory = response.categoryName;
					}
					let userRefinementData;
					if (itemPrediction) {
						userRefinementData = (await fetchUserRefinementData(
							`item-refinements/from/${refinementData.tid}/${itemPrediction.tenderItemId}`
						)) as UserItemRefinement;
					}

					const obj = {
						isOffer: false,
						isTenderGroup: true,
						itemPredictionId: itemPrediction.id,
						longText: itemPrediction.longText,
						numOfEiwa: 0,
						numOfHawa: 0,
						offer: false,
						parentItem: ctgy.tid,
						parentUUID: '',
						position: `${parentObj.position}.${child.rNoPart}`,
						probability: 1,
						productCategory,
						quantity: 1,
						refinementId: userRefinementData?.tid,
						shortText: child.lblTx,
						sorting: 1,
						status: userRefinementData?.status,
						tid: child.tid,
						unit: '',
						userItemRefinementCategories: [],
						userItemRefinementSuppliers: userRefinementData?.supplierAssigments
					};
					normalizedItems.entities[obj.tid] = obj;
					const boqCtgy2 = (await fetchData(`bo-q-ctgies/${child.tid}`)) as BoqCtgiesData;
					const childBody2 = (await fetchData(
						`bo-q-bodies/${boqCtgy2.childBoQBody.tid}`
					)) as BoQBody;
					if (childBody2.boQCtgies.length) {
						childBody2.boQCtgies.forEach(async (childCtg) => {
							normalizedItems.ids.push(childCtg.tid);
							const childObj = {
								isOffer: false,
								isTenderGroup: true,
								longText: '',
								numOfEiwa: 0,
								numOfHawa: 0,
								offer: false,
								parentItem: obj.tid,
								parentUUID: '',
								position: `${obj.position}.${childCtg.rNoPart}`,
								probability: 1,
								quantity: 1,
								shortText: childCtg.lblTx,
								sorting: 2,
								status: 'OPEN',
								tid: childCtg.tid,
								unit: '',
								userItemRefinementCategories: []
							};
							normalizedItems.entities[childObj.tid] = childObj;
						});
					} else {
						const { items } = (await fetchData(`itemlists/${childBody2.itemlist.tid}`)) as ItemList;
						items.forEach(async (item, index) => {
							normalizedItems.ids.push(item.tid);
							const itemPrediction = (await fetchAiPredictionsData(
								`item-predictions/from/${predictionId}/${item.tid}`
							)) as ItemPrediction;
							let productCategory = '';
							if (itemPrediction.productCategoryPredictions?.length) {
								const response = (await fetchAiPredictionsData(
									`product-category-predictions/${itemPrediction.productCategoryPredictions[0].id}`
								)) as any;
								productCategory = response.categoryName;
							}
							let userRefinementData;
							if (itemPrediction?.tenderItemId) {
								userRefinementData = (await fetchUserRefinementData(
									`item-refinements/from/${refinementData.tid}/${itemPrediction.tenderItemId}`
								)) as UserItemRefinement;
							}

							const childObj = {
								isOffer: !!userRefinementData?.isOffer,
								isTenderGroup: false,
								itemPredictionId: itemPrediction.id,
								longText: itemPrediction.longText,
								numOfEiwa: userRefinementData?.productAssigments.length,
								numOfHawa: 0,
								offer: false,
								parentItem: obj.tid,
								parentUUID: '',
								position: `${obj.position}.${index + 1}`,
								probability: 1,
								productAssigments: userRefinementData?.productAssigments || [],
								productCategory,
								productPredictions: itemPrediction.productPredictions,
								quantity: item.quantity,
								refinementId: userRefinementData?.tid,
								shortText: itemPrediction.shortText,
								sorting: 0,
								status: userRefinementData?.status,
								tid: item.tid,
								unit: item.quantityUnit,
								userItemRefinementCategories: [],
								userItemRefinementSuppliers: userRefinementData?.supplierAssigments
							};
							normalizedItems.entities[childObj.tid] = childObj;
						});
					}
				});
		});
		return normalizedItems;
	};
	const normalizedItems = await getItems();

	return { data: normalizedItems, totalItems: aiPredictions.itemCount };
};

export default getInitialTree;

import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { RootState } from '..';
import type {
	ItemRefinement,
	ProductAssigment,
	ShoppingCard
} from '../../interfaces/UserRefinement2.types';
import type { ProductType } from '../../screens/TenderItems/types/ProductType.types';
import type { AKTType } from '../../types';
import { baseQuery } from '../stateUtils';
import { updateTenderItem, updateTenderItems } from '../tenderItems/tenderItemsState';
import { updateSuppliers } from '../tenderItems/tenderItemsSuppliersState';

const USER_REFINEMENT_URL = 'services/userrefinement2/api/';
export interface TenderRefinement {
	tid: string;
	predictionId: string;
	akt: string;
	gaebTid: string;
	createdAt: Date;
	updatedAt: Date;
	status: string;
}

export type ProductAssignmentBodyType = {
	id: string;
	productId: string;
	productStatus: string;
	listOrderIndex: number;
	itemRefinement: {
		tid: string;
	};
};

const userRefinement2Api = createApi({
	baseQuery,
	endpoints: (builder) => ({
		createProductAssigment: builder.mutation<ProductAssignmentBodyType, any>({
			async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					const { itemRefinement, ...rest } = data;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[id];
					dispatch(
						updateTenderItem({
							...tenderItem,
							productAssigments: [...tenderItem.productAssigments, rest]
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ id, tenderId, ...body }) => ({
				body,
				method: 'POST',
				url: `${USER_REFINEMENT_URL}product-assigments`
			})
		}),
		createProductAssigmentBulk: builder.mutation({
			async queryFn(args, _queryApi) {
				const response = await Promise.all(
					args.map((arg: ProductAssignmentBodyType) =>
						_queryApi.dispatch(userRefinement2Api.endpoints.createProductAssigment.initiate(arg))
					)
				);

				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}
				const products = response.map((product: any) => product.data);
				return { data: { products } };
			}
		}),
		createSupplierAssignment: builder.mutation<
			{
				supplierId: string;
				supplierName: string;
				listOrderIndex: number;
				itemRefinement: { tid: string };
			},
			{
				supplierId: string;
				supplierName: string;
				listOrderIndex: number;
				itemRefinement: { tid: string };
				tenderId: string;
			}
		>({
			invalidatesTags: ['ShoppingCard'],
			async onQueryStarted({ tenderId }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					const { itemRefinement, ...rest } = data;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					dispatch(
						updateTenderItem({
							...tenderItem,
							userItemRefinementSuppliers: [...tenderItem.userItemRefinementSuppliers, rest]
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ tenderId, ...body }) => ({
				body,
				method: 'POST',
				url: `${USER_REFINEMENT_URL}supplier-assigments`
			})
		}),
		deleteProductAssigment: builder.mutation<ProductAssignmentBodyType, any>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[id];
					const parentItem = tenderItems.data[tenderItem.parentItem];
					const { [id]: removedId, ...data } = tenderItems.data;
					const newData = {
						...data,
						[parentItem.tid]: {
							...parentItem,
							children: parentItem.children.filter((child: any) => child !== id),
							productAssigments: parentItem.productAssigments.filter(
								(product: any) => product.tid !== id
							)
						}
					};
					dispatch(updateTenderItems({ treeStructure: newData }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ tid }) => ({
				method: 'DELETE',
				url: `${USER_REFINEMENT_URL}product-assigments/${tid}`
			})
		}),
		deleteSupplierAssignment: builder.mutation<string, { supplierId: string; tenderId: string }>({
			invalidatesTags: ['ShoppingCard'],
			async onQueryStarted({ tenderId, supplierId }, { dispatch, queryFulfilled, getState }) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					dispatch(
						updateTenderItem({
							...tenderItem,
							userItemRefinementSuppliers: tenderItem.userItemRefinementSuppliers.filter(
								(supplier: any) => supplier.id !== supplierId
							)
						})
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ supplierId }) => ({
				method: 'DELETE',
				url: `${USER_REFINEMENT_URL}supplier-assigments/${supplierId}`
			})
		}),
		deleteSupplierAssignmentBulk: builder.mutation<
			string,
			{ supplierIds: string[]; tenderId: string }
		>({
			// @ts-ignore
			async queryFn({ supplierIds, tenderId }, _queryApi) {
				const response = await Promise.all(
					supplierIds.map((supplierId: string) =>
						_queryApi.dispatch(
							userRefinement2Api.endpoints.deleteSupplierAssignment.initiate({
								supplierId,
								tenderId
							})
						)
					)
				);
				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}

				return { data: {} };
			}
		}),
		editItemRefinement: builder.mutation<
			TenderRefinement,
			{ status: string; tid: string; id: string }
		>({
			async onQueryStarted({ id, status }, { dispatch, queryFulfilled, getState }) {
				try {
					const { data } = await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[id];
					const parentItem = tenderItems.data[tenderItem.parentItem];
					if (parentItem) {
						const { children } = parentItem as unknown as any;
						const shouldUpdateParent = children
							.filter((child: string) => child !== id)
							.every((child: string) => {
								const tenderItem = tenderItems.data[child];
								return tenderItem.status === status;
							});

						if (parentItem.status !== status && shouldUpdateParent) {
							dispatch(
								userRefinement2Api.endpoints.editItemRefinement.initiate({
									id: parentItem.tid,
									status,
									tid: parentItem.refinementId!
								})
							);
						}
					}
					dispatch(updateTenderItem({ ...tenderItem, status: data.status }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ id, ...body }) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}item-refinements/${body.tid}`
			})
		}),
		editProductAssigment: builder.mutation<
			ProductType,
			{ tid: string; quantity?: number; positionType: string }
		>({
			async onQueryStarted({ tid, ...rest }, { dispatch, queryFulfilled, getState }) {
				try {
					await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tid];
					dispatch(updateTenderItem({ ...tenderItem, ...rest }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}product-assigments/${body.tid}`
			})
		}),
		fetchItemRefinementsFromDoc: builder.query<
			TenderRefinement,
			{ docRefinementId: string; tenderId: string }
		>({
			query: ({ docRefinementId, tenderId }) =>
				`${USER_REFINEMENT_URL}item-refinements/from/${docRefinementId}/${tenderId}`
		}),
		fetchProductAssigment: builder.query<ProductAssigment, string>({
			query: (tid) => `${USER_REFINEMENT_URL}product-assigments/${tid}`
		}),
		fetchProductAssigments: builder.query({
			async onQueryStarted({ tenderId }, { queryFulfilled, dispatch, getState }) {
				try {
					const { data } = await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					dispatch(updateTenderItem({ ...tenderItem, productAssigments: data.products }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			async queryFn({ ids }, _queryApi) {
				const response = await Promise.all(
					ids.map((id: string) =>
						_queryApi.dispatch(userRefinement2Api.endpoints.fetchProductAssigment.initiate(id))
					)
				);

				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}

				const products = response.map((product: any) => product.data);
				return { data: { products } };
			}
		}),
		fetchShoppingCards: builder.query<ShoppingCard[], string>({
			providesTags: ['ShoppingCard'],
			query: (tenderRefinementId) =>
				`${USER_REFINEMENT_URL}shopping-cards/${tenderRefinementId}/shopping-cards`
		}),
		fetchSupplierAssigment: builder.query<any, string>({
			query: (tid) => `${USER_REFINEMENT_URL}supplier-assigments/${tid}`
		}),
		fetchSupplierAssigments: builder.query({
			async onQueryStarted({ tenderId }, { queryFulfilled, dispatch, getState }) {
				try {
					const { data } = await queryFulfilled;
					// @ts-ignore
					const { tenderItems } = getState().tenderItems;
					const tenderItem = tenderItems.data[tenderId];
					const userItemRefinementSuppliers = data.suppliers.map((supplier: any) => {
						const newSupplier = {
							addedByUser: true,
							id: supplier.tid,
							label: supplier.supplierName,
							probability: 1,
							supplierId: supplier.supplierId,
							tid: supplier.tid,
							value: supplier.supplierName
						};
						return newSupplier;
					});
					dispatch(updateTenderItem({ ...tenderItem, userItemRefinementSuppliers }));
					dispatch(updateSuppliers({ tenderId, userItemRefinementSuppliers }));
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			async queryFn({ ids }, _queryApi) {
				const response = await Promise.all(
					ids.map((id: string) =>
						_queryApi.dispatch(userRefinement2Api.endpoints.fetchSupplierAssigment.initiate(id))
					)
				);
				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}
				const suppliers = response.map((supplier: any) => supplier.data);
				return { data: { suppliers } };
			}
		}),
		fetchSupplierAssigmentsBulk: builder.query({
			async queryFn(args, _queryApi) {
				const response = await Promise.all(
					args.map((arg: { ids: string; tenderId: string }) =>
						_queryApi.dispatch(userRefinement2Api.endpoints.fetchSupplierAssigments.initiate(arg))
					)
				);
				const error = response.some((data) => 'error' in data);
				if (error) {
					throw new Error();
				}
				return { data: {} };
			}
		}),
		fetchTenderRefinements: builder.query<TenderRefinement, { akt: AKTType; id: string }>({
			query: ({ akt, id }) => `${USER_REFINEMENT_URL}tender-refinements/ensure/for/${akt}/${id}`
		})
	}),
	reducerPath: 'userRefinement2',
	tagTypes: ['ShoppingCard']
});

export const {
	useFetchTenderRefinementsQuery,
	useFetchItemRefinementsFromDocQuery,
	useEditItemRefinementMutation,
	useCreateSupplierAssignmentMutation,
	useDeleteSupplierAssignmentMutation,
	useEditProductAssigmentMutation,
	useCreateProductAssigmentMutation,
	useDeleteProductAssigmentMutation,
	useCreateProductAssigmentBulkMutation,
	useFetchProductAssigmentsQuery,
	useDeleteSupplierAssignmentBulkMutation,
	useFetchSupplierAssigmentsQuery,
	useFetchShoppingCardsQuery,
	useFetchSupplierAssigmentsBulkQuery
} = userRefinement2Api;

export const { select: selectShoppingCards } = userRefinement2Api.endpoints.fetchShoppingCards;
export const { select: selectTenderRefinements } =
	userRefinement2Api.endpoints.fetchTenderRefinements;
export const selectItemsFromShoppingCards = createSelector(
	(state: RootState) => state,
	(state: RootState, tenderRefinementId: string, shoppingCardId: string) => [
		selectShoppingCards(tenderRefinementId)(state),
		shoppingCardId
	],
	(state: RootState, shoppingCardsData: any) => {
		const [{ data }, supplierId] = shoppingCardsData;
		const { tenderItems } = state.tenderItems;
		if (data?.length && tenderItems?.treeData?.length) {
			const supplier = data?.find(
				(card: ShoppingCard) => card.supplierInfo.supplierId === supplierId
			);
			if (supplier?.itemRefinements) {
				const tenderItemTids = supplier.itemRefinements.map(
					(itemRefinement: ItemRefinement) => itemRefinement.tenderItemTid
				);
				const tenderData = tenderItemTids?.map((tenderItemTid: string) => ({
					...tenderItems.data[tenderItemTid],
					productAssigments: []
				}));
				return tenderData.filter((tender: any) => 'tid' in tender);
			}
		}
		return [];
	}
);
export { userRefinement2Api };

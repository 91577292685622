import { current } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { ContactPerson } from '../../interfaces';
import type {
	AddContactPersonFormDataType,
	AddressSectionType,
	AddSupplierContactPersonFormDataType
} from '../../types';
import { projectsApi } from '../projects/projectsSlice';
import { shoppingCartsApi } from '../shoppingCarts/shoppingCartsSlice';
import { baseQuery, CONTACTS_URL } from '../stateUtils';

const contactPersonApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createContactPerson: builder.mutation<
				ContactPerson & AddressSectionType,
				AddContactPersonFormDataType | AddSupplierContactPersonFormDataType
			>({
				async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
					if (!patch.supplier) {
						return;
					}
					let patchedResult;
					try {
						const { data: addedContact } = await queryFulfilled;
						const { supplier, ...contactPerson } = addedContact;
						const projectId = window.location.pathname.split('/')[2];
						const { data: project } = await dispatch(
							projectsApi.endpoints.fetchProject.initiate(projectId)
						);

						patchedResult = dispatch(
							shoppingCartsApi.util.updateQueryData(
								'fetchAllShoppingCarts',
								{ akt: project!.responsibleOrganisation.sGArea, id: project!.gaeb!.contentUUID },
								(shoppingCarts) => {
									const currentState = current(shoppingCarts);
									const updatedCart = currentState.entities[patch.supplier!.cRMID];
									const contactPeople = updatedCart!.supplier?.contactPeople.length
										? [...updatedCart.supplier.contactPeople, contactPerson]
										: [contactPerson];
									Object.assign(shoppingCarts, {
										entities: {
											...currentState.entities,
											[patch.supplier!.cRMID]: {
												...updatedCart,
												supplier: {
													...supplier,
													contactPeople
												}
											}
										}
									});
								}
							)
						);
					} catch {
						patchedResult?.undo();
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: (contactPerson) => ({
					body: {
						...contactPerson
					},
					method: 'POST',
					url: `${CONTACTS_URL}contact-people`
				})
			}),
			editContactPerson: builder.mutation<
				ContactPerson & AddressSectionType,
				AddContactPersonFormDataType | AddSupplierContactPersonFormDataType
			>({
				async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
					let patchedResult;
					try {
						await queryFulfilled;
						const projectId = window.location.pathname.split('/')[2];
						const { data: project } = await dispatch(
							projectsApi.endpoints.fetchProject.initiate(projectId)
						);

						patchedResult = dispatch(
							shoppingCartsApi.util.updateQueryData(
								'fetchAllShoppingCarts',
								{ akt: project!.responsibleOrganisation.sGArea, id: project!.gaeb!.contentUUID },
								(shoppingCarts) => {
									const currentState = current(shoppingCarts);
									const updatedCart = currentState.entities[patch.supplier!.cRMID];
									const contactPeople = updatedCart.supplier?.contactPeople.map(
										(supplierContact) => {
											if (supplierContact.id === patch.id) {
												return patch;
											}

											return supplierContact;
										}
									);

									Object.assign(shoppingCarts, {
										entities: {
											...currentState.entities,
											[patch.supplier!.cRMID]: {
												...updatedCart,
												supplier: {
													...updatedCart.supplier,
													contactPeople
												}
											}
										}
									});
								}
							)
						);
					} catch {
						patchedResult?.undo();
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: (contactPerson) => ({
					body: {
						...contactPerson
					},
					method: 'PATCH',
					url: `${CONTACTS_URL}contact-people/${contactPerson.id}`
				})
			})
		};
	},
	reducerPath: 'contactPerson'
});

export const { useCreateContactPersonMutation, useEditContactPersonMutation } = contactPersonApi;

export { contactPersonApi };

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../stateUtils';

const aiPredictionsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchAIPredictions: builder.query({
				query: ({ id, akt }) => `services/aipredictions2/api/predictions/ensure/for/${akt}/${id}`
			}),
			fetchAIPredictionsForItems: builder.query({
				query: ({ predictionId, tenderId }) =>
					`services/aipredictions2/api/item-predictions/from/${predictionId}/${tenderId}`
			}),
			fetchAIPredictionsStatus: builder.query({
				query: (id) => `services/aipredictions/api/predictions/no-items/${id}`
			}),
			fetchAIProductsPredictions: builder.query({
				query: (id) => `services/aipredictions2/api/product-predictions/${id}`
			}),
			fetchAIProductsPredictionsBulk: builder.query({
				async queryFn(ids, _queryApi, _extraOptions, baseQuery) {
					const response = await Promise.all(
						ids.map((id: string) =>
							baseQuery(`services/aipredictions2/api/product-predictions/${id}`)
						)
					);
					const products = response.map((product: any) => product.data);
					return { data: products };
				}
			})
		};
	},
	reducerPath: 'aiPredictions'
});

export const {
	useFetchAIPredictionsStatusQuery,
	useFetchAIPredictionsQuery,
	useFetchAIProductsPredictionsQuery,
	useFetchAIProductsPredictionsBulkQuery
} = aiPredictionsApi;

export const selectAiProductsPredictions =
	aiPredictionsApi.endpoints.fetchAIProductsPredictionsBulk.select;

export { aiPredictionsApi };

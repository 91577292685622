import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, SUPPLIER_PRODUCT_PROPOSAL } from '../stateUtils';

const supplierProductProposalApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchSupplierProductProposals: builder.query<any, string>({
				query: (id) => `${SUPPLIER_PRODUCT_PROPOSAL}proposals/ensureFor/${id}`
			})
		};
	},
	reducerPath: 'supplierProductProposal'
});

export const { useFetchSupplierProductProposalsQuery } = supplierProductProposalApi;

export { supplierProductProposalApi };

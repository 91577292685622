import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { TenderItem } from '../../interfaces';
import type { TenderItemSupplierTypes } from '../../types';
import type { UserItemSupplierRefinementType } from '../../types/SupplierRefinementPayloadType.type';
import type { RootState } from '../index';
import { userRefinementApi } from '../userRefinement/userRefinementSlice';

const initialState = {
	previousState: [],
	temporaryIdsForDeletion: []
} as {
	[key: string]: {
		[key: string]: TenderItemSupplierTypes;
	}[];
	// @ts-ignore
	temporaryIdsForDeletion: string[];
	previousState: {
		[key: string]: TenderItemSupplierTypes;
	}[];
};

const tenderItemSuppliers = createSlice({
	extraReducers: (builder) => {
		builder.addMatcher(
			userRefinementApi.endpoints?.fetchTenderItems.matchFulfilled,
			(state, { meta, payload }) => {
				const positionSuppliers = Object.values(payload.data).reduce((acc, curr) => {
					if (!(curr as TenderItem).isTenderGroup) {
						const position = curr as TenderItem;
						// eslint-disable-next-line no-param-reassign
						acc = {
							...acc,
							[position.tid]: [...position.userItemRefinementSuppliers]
								?.sort((a, b) => (a?.probability < b?.probability ? 1 : -1))
								.map((supplier) => ({
									...supplier,
									id: supplier.tid,
									label: supplier.supplierName,
									value: supplier.tid
								}))
						};
					}
					return acc;
				}, {});
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				state[meta.arg.originalArgs] = positionSuppliers;
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				state.previousState = positionSuppliers;
			}
		);
	},
	initialState,
	name: 'tenderItemSuppliers',
	reducers: {
		addSuppliersForDeletion(state: any, action: PayloadAction<string>) {
			state.temporaryIdsForDeletion.push(action.payload);
		},
		cancelSetSuppliers(
			state: any,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			action: PayloadAction<{
				contentUUID: string;
			}>
		) {
			const { contentUUID } = action.payload;
			// eslint-disable-next-line no-param-reassign
			state[contentUUID] = state.previousState;
			state.temporaryIdsForDeletion.splice(0, state.temporaryIdsForDeletion.length);
		},
		deleteBulkSuppliersInTenderItem(
			state: any,
			action: PayloadAction<{
				selectorId: string;
				supplier: TenderItemSupplierTypes;
			}>
		) {
			const { selectorId, supplier } = action.payload;
			const [contentUUID, tenderItem] = selectorId.split('/');

			const idsForDeletion = current(state[contentUUID][tenderItem])
				.filter(
					(supp: UserItemSupplierRefinementType) => supp.supplierName === supplier.supplierName
				)
				.map((supp: UserItemSupplierRefinementType) => supp.tid)
				.filter((id: string) => id.includes('-'));

			if (supplier?.tid.includes('-')) {
				idsForDeletion.push(supplier.tid);
			}

			const suppliers = current(state[contentUUID][tenderItem]).filter(
				(supp: UserItemSupplierRefinementType) => supp.supplierName !== supplier.supplierName
			);

			// eslint-disable-next-line no-param-reassign
			state.temporaryIdsForDeletion = Array.from(
				new Set([...state.temporaryIdsForDeletion, ...idsForDeletion])
			);
			// eslint-disable-next-line no-param-reassign
			state[contentUUID][tenderItem] = suppliers;
		},
		setSuppliers(state: any, action: PayloadAction<{ contentUUID: string }>) {
			const { contentUUID } = action.payload;
			state.temporaryIdsForDeletion.splice(0, state.temporaryIdsForDeletion.length);
			// eslint-disable-next-line no-param-reassign
			state.previousState = state[contentUUID];
		},
		updateMultiSuppliersInTenderItem(
			state: any,
			action: PayloadAction<{
				selectorId: string;
				suppliers: TenderItemSupplierTypes[];
			}>
		) {
			const { selectorId, suppliers } = action.payload;
			const [contentUUID, tenderItem] = selectorId.split('/');
			const tender = current(state[contentUUID][tenderItem]);
			const removeDuplicates = (array: any) => {
				const seen = new Set();
				const uniqueOptions = array.filter((item: any) => {
					const duplicate = seen.has(item.supplierId);
					seen.add(item.supplierId);
					return !duplicate;
				});

				return uniqueOptions;
			};

			// eslint-disable-next-line no-param-reassign
			state[contentUUID][tenderItem] = removeDuplicates([...tender, ...suppliers]);
		},
		updateSuppliersAfterEdit(
			state: any,
			action: PayloadAction<{
				selectorId: string;
				suppliers: TenderItemSupplierTypes[];
			}>
		) {
			const { selectorId, suppliers } = action.payload;
			const [contentUUID, tenderItem] = selectorId.split('/');
			const tender = current(state[contentUUID][tenderItem]);
			const supplierToIdMap = Object.fromEntries(
				suppliers.map((supplier: any) => [supplier.supplierId, supplier.tid])
			);
			const updatedSuppliers = tender
				.filter((supplier: any) => supplierToIdMap[supplier.supplierId] !== undefined)
				.map((supplier: any) => ({ ...supplier, tid: supplierToIdMap[supplier.supplierId] }));

			// eslint-disable-next-line no-param-reassign
			state[contentUUID][tenderItem] = updatedSuppliers;
		},

		updateSuppliersInTenderItem(
			state: any,
			action: PayloadAction<{
				selectorId: string;
				suppliers: TenderItemSupplierTypes[];
			}>
		) {
			const { selectorId, suppliers } = action.payload;
			const [contentUUID, tenderItem] = selectorId.split('/');

			// eslint-disable-next-line no-param-reassign
			state[contentUUID][tenderItem] = suppliers;
		}
	}
});

export default tenderItemSuppliers.reducer;
export const {
	addSuppliersForDeletion,
	updateSuppliersInTenderItem,
	deleteBulkSuppliersInTenderItem,
	cancelSetSuppliers,
	setSuppliers,
	updateMultiSuppliersInTenderItem,
	updateSuppliersAfterEdit
} = tenderItemSuppliers.actions;
export const selectSuppliersOfTenderItems = (state: RootState) => state.tenderItemSuppliers;
export const selectPreviousState = (state: RootState) => state.tenderItemSuppliers.previousState;
export const selectUpdatedSupplierList = (state: RootState) =>
	state.tenderItemSuppliers.updatedSuppliersList;

export const selectSuppliersOfTenderItem = createSelector(
	[selectSuppliersOfTenderItems, (state, selectorId) => selectorId],
	(selectedSuppliers, selectorId) => {
		const [contentUUID, tenderItem] = selectorId.split('/');
		// @ts-ignore
		return tenderItem ? selectedSuppliers[contentUUID][tenderItem] : selectedSuppliers[contentUUID];
	}
);

import { createSlice, current } from '@reduxjs/toolkit';

import { type RootState } from '..';
import type { GenericObjectType, ProductForTableType } from '../../types';
import { userRefinementApi } from '../userRefinement/userRefinementSlice';

const searchAiPredictionsProductsSlice = createSlice({
	extraReducers: (builder) => {
		builder.addMatcher(
			userRefinementApi.endpoints?.fetchRefinementForTenderItemsProduct.matchFulfilled,
			(state, { payload }) => {
				const entities = payload.aiPredictionsForTenderItem.reduce(
					(acc: any, curr: ProductForTableType) => {
						// eslint-disable-next-line no-param-reassign
						acc = { ...acc, [curr.id]: curr };
						return acc;
					},
					{}
				);

				// eslint-disable-next-line no-param-reassign
				state.data = {
					entities,
					ids: entities ? Object.keys(entities) : []
				};
				// eslint-disable-next-line no-param-reassign
				state.products = payload.aiPredictionsForTenderItem;
			}
		);
	},
	initialState: {
		data: {
			entities: {},
			ids: []
		},
		products: []
	} as {
		data: {
			entities: GenericObjectType<ProductForTableType>;
			ids: string[];
		};
		products: ProductForTableType[];
	},
	name: 'aiPredictonsProducts',
	reducers: {
		searchProducts: (state, action) => {
			const { products } = current(state);

			const query: string = action.payload.toLowerCase();

			const entities = products
				.filter(
					(product) =>
						product.longText.toLowerCase().includes(query) ||
						product.shortText.toLowerCase().includes(query) ||
						product.idnlf?.toLowerCase().includes(query)
				)
				.reduce((acc: any, curr: ProductForTableType) => {
					// eslint-disable-next-line no-param-reassign
					acc = { ...acc, [curr.id]: curr };
					return acc;
				}, {});

			return {
				...state,
				data: {
					entities,
					ids: entities ? Object.keys(entities) : []
				}
			};
		}
	}
});

export const { searchProducts } = searchAiPredictionsProductsSlice.actions;
export const selectProducts = (state: RootState) => state.aiPredictionsProducts;

export default searchAiPredictionsProductsSlice.reducer;

import type {
	AssignedLineItem,
	Product,
	TenderItemRefinement,
	UserRefinementSupplierProduct
} from '../../interfaces';
// import type { ProductMasterDataType } from '../../interfaces/TenderItemRefinement.types';
import formatPrice from '../../utils/formatPrice';

const fetchRefinementForTenderItemsProductTransformResponse = (
	response: TenderItemRefinement,
	_meta: any,
	arg: any
) => {
	const userRefinedTenderItemProductsIds: string[] = [];

	return {
		aiPredictionsForTenderItem: response.aiPredictedHulkProducts
			?.map((product: Product) => ({
				bomItemList: '',
				eiwaHawaType: product.eiwaHawaType,
				group: 'product/text',
				id: product.id,
				idnlf: product.idnlf,
				longText: product.longText,
				materialNumber: '',
				materialType: product.materialType,
				position: '',
				positionType: '',
				price: '',
				product: true,
				productCategory: product.materialCategory,
				quantity: '',
				quoteDate: '',
				quoteId: '',
				rabate1: '',
				rabate2: '',
				rabate3: '',
				rabateGroup: '',
				shortText: product.shortText,
				status: product.status,
				supplier: product.supplier,
				ttItemId: product.maraId,
				unitOfMeasure: product.unitOfMeasure
			}))
			.filter((product) => product.status !== 'Obsolete'),
		userRefinedTenderItemProducts: response.selectedHulkProducts
			?.filter((product) => product)
			.map((product: Product) => {
				let hawaProduct;
				let eiwaProduct;
				const isEiwaHawaTypeText = product.eiwaHawaType === 'TEXT';
				const isProductHAWAType = product.eiwaHawaType !== 'EIWA';

				if (isProductHAWAType) {
					const hawaProducts = response.userRefinementProducts.filter(
						(item: UserRefinementSupplierProduct) => item.productId === product.maraId
					);
					const newHawaProducts = hawaProducts.filter(
						(hawaProduct) => !userRefinedTenderItemProductsIds.includes(hawaProduct.tid)
					);
					hawaProduct = newHawaProducts[0] || hawaProducts[0];
					if (hawaProduct) {
						userRefinedTenderItemProductsIds.push(hawaProduct.tid);
					}
				} else {
					const eiwaProducts = response.userRefinementProducts.filter(
						(item: AssignedLineItem) => item.productId === product.maraId
					);
					const newEiwaProducts = eiwaProducts.filter(
						(eiwaProduct) => !userRefinedTenderItemProductsIds.includes(eiwaProduct.tid)
					);
					eiwaProduct = newEiwaProducts[0] || eiwaProducts[0];
					if (eiwaProduct) {
						userRefinedTenderItemProductsIds.push(eiwaProduct.tid);
					}
				}

				return {
					bomItemList: '',
					editableStatus: product.editableStatus,
					eiwaHawaType: product.eiwaHawaType,
					id: isProductHAWAType ? hawaProduct?.tid : eiwaProduct?.tid,
					idnlf: product.idnlf,
					longText: hawaProduct?.overrideData?.longText || product.longText,
					materialNumber: hawaProduct?.materialNumber || '',
					materialType: product.materialType,
					parentUUID: arg,
					position: isProductHAWAType ? hawaProduct.position : eiwaProduct.position,
					positionType: isEiwaHawaTypeText
						? 'ZATX'
						: hawaProduct?.positionType || eiwaProduct?.positionType,
					price: isProductHAWAType ? hawaProduct?.userRefinementSupplierProduct?.supplierPrice : '',
					priceType: isProductHAWAType
						? hawaProduct?.userRefinementSupplierProduct?.supplierPriceType
						: '',
					product: true,
					productCategory: product.materialCategory,
					quantity: hawaProduct?.overrideData?.quantity,
					quoteDate: '',
					quoteId: hawaProduct?.userRefinementSupplierProduct.quoteID,
					rebate1: hawaProduct?.userRefinementSupplierProduct.rebate1
						? formatPrice(hawaProduct.userRefinementSupplierProduct.rebate1)
						: '',
					rebate2: hawaProduct?.userRefinementSupplierProduct.rebate2
						? formatPrice(hawaProduct.userRefinementSupplierProduct.rebate2)
						: '',
					rebate3: hawaProduct?.userRefinementSupplierProduct.rebate3
						? formatPrice(hawaProduct.userRefinementSupplierProduct.rebate3)
						: '',
					rebateGroup: hawaProduct?.userRefinementSupplierProduct.rebateGroup || '',
					shortText: hawaProduct?.overrideData?.shortText || product.shortText,
					status: hawaProduct?.productStatus || eiwaProduct?.productStatus,
					supplier: product.supplier,
					tid: isProductHAWAType ? hawaProduct?.tid : eiwaProduct?.tid,
					ttItemId: product.maraId,
					unit: hawaProduct?.overrideData?.unit || product?.unitOfMeasure,
					userRefinementSupplierProduct: isProductHAWAType
						? hawaProduct?.userRefinementSupplierProduct
						: null
				};
			})
	};
};

export default fetchRefinementForTenderItemsProductTransformResponse;

import type { EntityState } from '@reduxjs/toolkit';
import { createSelector, original } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import fetchRefinementForTenderItemsProductTransformResponse from './util';
import type {
	TenderItem,
	UserRefinementPosition,
	UserRefinementTenderPositions,
	UserRefinementTree
} from '../../interfaces';
import type {
	ProductForTableType,
	UserItemRefinementType,
	UserItemSingleRefinementType
} from '../../types';
import getSuppliers from '../../utils/getSuppliersForFilterDropdown';
import { initialTenderItemsState, tenderItemsAdapter } from '../adapters';
import type { RootState } from '../index';
import { shoppingCartsApi } from '../shoppingCarts/shoppingCartsSlice';
import { baseQuery, USER_REFINEMENT_URL } from '../stateUtils';

const addChildren = (ids: string[], treeData: Record<string, TenderItem>): TenderItem[] =>
	ids.map((id) => ({
		...treeData[id],
		children: treeData[id].children
			? addChildren(treeData[id].children as string[], treeData)
			: undefined
	}));

const mapOverItems = (treeStructuredData: Record<string, TenderItem>, parentlessIds: string[]) =>
	parentlessIds.map((parentlessId) => ({
		...treeStructuredData[parentlessId],
		children: treeStructuredData[parentlessId].children
			? addChildren(treeStructuredData[parentlessId].children as string[], treeStructuredData)
			: undefined
	}));

// This function is trying to normalize data and create tree like data needed for react-arborist tree representation like component
// The goal was to make as fewer iterations as possible (there are only 2 here, one of which is recursive).
// The reason why we need to ignore this rule: eslint-disable-next-line no-param-reassign is because
// other option is to heavily use spread syntax (...previousValue, currentValue) which would keep immutability of data,
// but would slow down the performance of the function due to the copying and storing (until GB gets it) of large amount of data
const normalizeTenderItemsData = ({
	toCheckCounter,
	items,
	refinedCounter,
	status
}: {
	items: EntityState<UserRefinementPosition, string>;
	refinedCounter: number;
	status: 'DONE' | 'PREDICTING' | 'READY';
	toCheckCounter: number;
}) => {
	const parentlessIds: string[] = [];
	/* eslint-disable no-param-reassign */
	const createTreeStructure = (items: EntityState<UserRefinementPosition, string>) =>
		items.ids.reduce(
			(previousValue, currentValue) => {
				const tenderItem = items.entities[currentValue] as unknown as TenderItem;
				const { parentItem } = tenderItem;
				const hasParent = !!parentItem;
				const { isTenderGroup, tid } = tenderItem;
				const { numOfEiwa, numOfHawa, status } = items.entities[
					currentValue
				] as UserRefinementPosition;
				if (isTenderGroup && !hasParent) {
					previousValue[tid] = {
						...tenderItem,
						children: []
					};
					parentlessIds.push(tid);
				}
				if (isTenderGroup && hasParent) {
					const itemsParent = previousValue[parentItem as string];
					const newChildren = itemsParent.children;
					newChildren?.push(tid);
					previousValue[parentItem as string] = {
						...itemsParent,
						children: newChildren
					};
					previousValue[tid] = {
						...tenderItem,
						children: [],
						parentUUID: parentItem
					};
				}
				if (!isTenderGroup) {
					const itemsParent = previousValue[parentItem as string];
					const newChildren = itemsParent.children;
					newChildren?.push(tid);
					previousValue[parentItem as string] = {
						...itemsParent,
						children: newChildren
					};

					previousValue[tid] = {
						...tenderItem,
						numOfEiwa,
						numOfHawa,
						parentUUID: parentItem,
						status,
						...(['CONFIRMED', 'IN_PROGRESS'].includes(status) &&
							(numOfEiwa !== 0 || numOfHawa !== 0) && { children: [] })
					};
				}
				return previousValue;
			},
			{} as Record<string, TenderItem>
		);
	/* eslint-enable no-param-reassign */
	const treeStructure = createTreeStructure(items);

	return {
		data: treeStructure,
		parentlessIds,
		status,
		totalCheckedItems: refinedCounter,
		totalItems: refinedCounter + toCheckCounter,
		totalUncheckedItems: toCheckCounter,
		treeData: mapOverItems(treeStructure, parentlessIds)
	};
};
// TODO fix updating numbers in order not to count groups
const userRefinementApi = createApi({
	baseQuery,
	endpoints: (builder) => ({
		addUserRefinementToTenderItem: builder.mutation({
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				let patchedResult;
				try {
					const { data: userRefinementsForTenderItem } = await queryFulfilled;
					const { tid } = userRefinementsForTenderItem;
					dispatch(userRefinementApi.util.invalidateTags([{ id: tid, type: 'TenderItem' }]));
					dispatch(userRefinementApi.endpoints.fetchRefinementForTenderItems.initiate(tid));

					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							_,
							(currentTenderItemsState) => {
								const currentTenderItem = original(currentTenderItemsState!.data[tid]);
								const data = Object.assign(currentTenderItemsState.data, {
									[tid]: {
										...currentTenderItem,
										status: 'IN_PROGRESS'
									}
								});
								Object.assign(currentTenderItemsState, {
									data,
									treeData: mapOverItems(
										data as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					patchedResult?.undo();
				}
			},
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-item-refinements/${body.tid}`
			})
		}),
		deleteProduct: builder.mutation({
			async onQueryStarted({ ...patch }, { dispatch }) {
				let patchedResult;
				try {
					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							patch,
							(currentTenderItemsState) => {
								const currentTenderItem = original(currentTenderItemsState!.data[patch.cachingId]);
								const currentTenderParentItem = original(
									currentTenderItemsState!.data[currentTenderItem?.parentUUID!]
								) as TenderItem;
								const data = Object.assign(currentTenderItemsState.data, {
									[currentTenderParentItem!.tid]: {
										...currentTenderParentItem,
										children:
											currentTenderParentItem.children?.length === 1
												? undefined
												: currentTenderParentItem!.children!.filter(
														(item) => item !== patch.cachingId
													)
									}
								});
								Object.assign(currentTenderItemsState, {
									data,
									treeData: mapOverItems(
										data as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					patchedResult?.undo();
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query({ tid }) {
				return {
					method: 'DELETE',
					url: `${USER_REFINEMENT_URL}user-refinement-products/${tid}`
				};
			}
		}),
		deleteSuppliersInBulk: builder.mutation({
			query(body) {
				return {
					body,
					method: 'POST',
					url: `${USER_REFINEMENT_URL}user-item-refinement-suppliers/bulk-delete`
				};
			}
		}),
		editOneTenderItem: builder.mutation({
			async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
				let patchedResult;
				try {
					const { data: updatedTenderItem } = await queryFulfilled;
					dispatch(userRefinementApi.endpoints.fetchRefinementForTenderItems.initiate(patch.tid));
					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							patch,
							(currentTenderItemsState) => {
								const currentTenderItem = original(currentTenderItemsState!.data[patch.tid]);
								const shouldRaiseCheckedItems = ['CONFIRMED', 'DECLINED'].includes(patch.status);
								const data = Object.assign(currentTenderItemsState.data, {
									[patch.tid]: {
										...currentTenderItem,
										status: updatedTenderItem.status
									}
								});
								Object.assign(currentTenderItemsState, {
									data,
									totalCheckedItems:
										currentTenderItemsState.totalCheckedItems + (shouldRaiseCheckedItems ? 1 : -1),
									totalUncheckedItems:
										currentTenderItemsState.totalUncheckedItems -
										(shouldRaiseCheckedItems ? 1 : -1),
									treeData: mapOverItems(
										data as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					patchedResult?.undo();
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ status, tid, userTenderDocRefinement }) => ({
				body: {
					status,
					tid,
					userTenderDocRefinement
				},
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-item-refinements/${tid}`
			})
		}),
		editProduct: builder.mutation({
			async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
				let patchedResult;
				try {
					await queryFulfilled;
					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							{ id, ...patch },
							(currentTenderItemsState) => {
								const currentTenderItem = original(currentTenderItemsState!.data[id]);
								const { overrideData, isHawaType, ...rest } = patch;
								const updated = overrideData
									? {
											...overrideData,
											...rest
										}
									: { ...rest };

								const updateObjectForState: any = {
									[id]: {
										...currentTenderItem,
										...updated
									}
								};
								if (isHawaType === 'HAWA') {
									updateObjectForState[id] = {
										...updateObjectForState[id],
										price: updated.userRefinementSupplierProduct.supplierPrice,
										priceType: updated.userRefinementSupplierProduct.supplierPriceType,
										quoteDate: updated.userRefinementSupplierProduct.quoteDate,
										quoteId: updated.userRefinementSupplierProduct.quoteID,
										rebate1: updated.userRefinementSupplierProduct.rebate1,
										rebate2: updated.userRefinementSupplierProduct.rebate2,
										rebate3: updated.userRefinementSupplierProduct.rebate3,
										supplierId: updated.userRefinementSupplierProduct.supplierId,
										supplierProductId: updated.userRefinementSupplierProduct.supplierProductId,
										tradingGroup: updated.userRefinementSupplierProduct.tradingGroup,
										unitOfMeasure: overrideData.unit,
										userRefinementSupplierProduct: {
											// @ts-ignore
											...currentTenderItem.userRefinementSupplierProduct!,
											price: updated.userRefinementSupplierProduct.supplierPrice,
											quoteId: updated.userRefinementSupplierProduct.quoteID
										}
									};
								}

								const newData = {
									...original(currentTenderItemsState.data),
									...updateObjectForState
								};

								Object.assign(currentTenderItemsState, {
									data: newData,
									treeData: mapOverItems(
										newData as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					patchedResult?.undo();
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: ({ id, isHawaType, ...body }) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-refinement-products/${body.tid}`
			})
		}),
		editProductInBulk: builder.mutation({
			async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
				let patchedResult;
				try {
					await queryFulfilled;
					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							patch,
							(currentTenderItemsState) => {
								const updateObjectForState = {};
								patch.userItemRefinements
									.map((refinement: UserItemRefinementType) =>
										refinement.userRefinementProducts.map(
											// @ts-ignore
											(product: UserItemSingleRefinementType) => `${product.tid} ${refinement.tid}`
										)
									)
									.flat()
									.forEach((itemId: any) => {
										const [id, parentItemId] = itemId.split(' ');

										const currentTenderItem = original(currentTenderItemsState!.data[id]);

										const patchForCurrentTenderItem = patch.userItemRefinements
											.find((update: UserItemRefinementType) => update.tid === parentItemId)!
											.userRefinementProducts.find(
												(product: UserItemSingleRefinementType) => product.tid === id
											);
										const patchObject = {
											positionType: patchForCurrentTenderItem.positionType,
											price: parseFloat(
												patchForCurrentTenderItem.userRefinementSupplierProduct?.supplierPrice || 0
											).toFixed(2),
											priceType:
												patchForCurrentTenderItem.userRefinementSupplierProduct?.supplierPriceType,
											quoteId: patchForCurrentTenderItem.userRefinementSupplierProduct?.quoteID,
											rebate1: parseFloat(
												patchForCurrentTenderItem.userRefinementSupplierProduct?.rebate1 || 0
											).toFixed(2),
											rebate2: parseFloat(
												patchForCurrentTenderItem.userRefinementSupplierProduct?.rebate2 || 0
											).toFixed(2),
											rebate3: parseFloat(
												patchForCurrentTenderItem.userRefinementSupplierProduct?.rebate3 || 0
											).toFixed(2),
											rebateGroup:
												patchForCurrentTenderItem.userRefinementSupplierProduct?.rebateGroup
										};

										Object.assign(updateObjectForState, {
											[(currentTenderItem as TenderItem)!.tid]: {
												...currentTenderItem,
												...patchObject
											}
										});
									});
								const newData = {
									...original(currentTenderItemsState.data),
									...updateObjectForState
								};

								Object.assign(currentTenderItemsState, {
									data: newData,
									treeData: mapOverItems(
										newData as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					patchedResult?.undo();
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-tender-doc-refinements/${body.tid}`
			})
		}),
		editSupplier: builder.mutation({
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-item-refinements/${body.tid}`
			})
		}),
		editSuppliersInBulk: builder.mutation({
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(shoppingCartsApi.util.invalidateTags(['ShoppingCart']));
				} catch {
					/* empty */
				}
			},
			query: (body) => ({
				body,
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-tender-doc-refinements/${body.tid}`
			})
		}),
		editTenderItemsInBulk: builder.mutation({
			async onQueryStarted({ ...patch }, { dispatch }) {
				let patchedResult;
				try {
					const updateObjectForState = {};
					patchedResult = dispatch(
						userRefinementApi.util.updateQueryData(
							'fetchTenderItems',
							patch,
							(currentTenderItemsState) => {
								const changingStatusForItemIds: string[] = [];
								const shouldRaiseCheckedItems = patch.status !== 'IN_PROGRESS';

								patch.allAffectedItemIds.forEach((itemId: any) => {
									const currentTenderItem = original(currentTenderItemsState!.data[itemId]);
									if (!(currentTenderItem as TenderItem).isTenderGroup) {
										changingStatusForItemIds.push((currentTenderItem as TenderItem).tid);
									}
									Object.assign(updateObjectForState, {
										[itemId]: {
											...currentTenderItem,
											status: patch.status
										}
									});
								});
								const data = Object.assign(currentTenderItemsState.data, updateObjectForState);
								Object.assign(currentTenderItemsState, {
									data,
									...(changingStatusForItemIds.length && {
										totalCheckedItems:
											currentTenderItemsState.totalCheckedItems +
											(shouldRaiseCheckedItems
												? changingStatusForItemIds.length
												: -changingStatusForItemIds.length),
										totalUncheckedItems:
											currentTenderItemsState.totalUncheckedItems -
											(shouldRaiseCheckedItems
												? changingStatusForItemIds.length
												: -changingStatusForItemIds.length)
									}),
									treeData: mapOverItems(
										data as Record<string, TenderItem>,
										currentTenderItemsState.parentlessIds
									)
								});
							}
						)
					);
				} catch {
					patchedResult?.undo();
					// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
				}
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: ({ allAffectedItemIds, status, tid }) => ({
				body: { status, tid },
				method: 'PATCH',
				url: `${USER_REFINEMENT_URL}user-item-refinements/${tid}`
			})
		}),
		fetchExportVersion: builder.query<string, { exportName: string; id: string }>({
			query: ({ exportName, id }) =>
				`${USER_REFINEMENT_URL}user-item-refinement/export/${exportName}/${id}`
		}),
		fetchRefinementForTenderItems: builder.query<
			{
				aiPredictionsForTenderItem: ProductForTableType[];
				userRefinedTenderItemProducts: ProductForTableType[];
			},
			string
		>({
			async onQueryStarted(arg, { dispatch, queryFulfilled, updateCachedData }) {
				let patchedResult;
				try {
					await queryFulfilled;
					updateCachedData((draft) => {
						patchedResult = dispatch(
							userRefinementApi.util.updateQueryData(
								'fetchTenderItems',
								// @ts-ignore
								draft,
								(currentTenderItemsState) => {
									const currentTenderItem = original(currentTenderItemsState!.data[arg]);
									const originalDraftUserRefinedTenderItemProducts = original(
										draft.userRefinedTenderItemProducts
									);
									const children = originalDraftUserRefinedTenderItemProducts!.map((product) => ({
										...product,
										quantity: product.quantity || ''
									}));

									const data = Object.assign(currentTenderItemsState.data, {
										...children?.reduce((acc, curr) => {
											// eslint-disable-next-line no-param-reassign
											acc = {
												...acc,
												[curr.id]: {
													...curr
												}
											};
											return acc;
										}, {}),
										[arg]: {
											...currentTenderItem,
											children: children?.map((product) => product.id)
										}
									});

									Object.assign(currentTenderItemsState, {
										data,
										treeData: mapOverItems(
											data as Record<string, TenderItem>,
											currentTenderItemsState.parentlessIds
										)
									});
								}
							)
						);
					});
				} catch {
					// @ts-ignore
					patchedResult?.undo();
				}
			},
			providesTags: (result, error, arg) => [{ id: arg, type: 'TenderItem' }],
			query: (id) => `${USER_REFINEMENT_URL}user-item-refinements/${id}`,
			// @ts-ignore
			transformResponse: fetchRefinementForTenderItemsProductTransformResponse
		}),
		fetchRefinementForTenderItemsProduct: builder.query<
			{
				aiPredictionsForTenderItem: ProductForTableType[];
				userRefinedTenderItemProducts: ProductForTableType[];
			},
			string
		>({
			query: (id) => `${USER_REFINEMENT_URL}user-item-refinements/${id}`,
			// @ts-ignore
			transformResponse: fetchRefinementForTenderItemsProductTransformResponse
		}),
		fetchTenderItems: builder.query<UserRefinementTree, string>({
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			query: (id) => `${USER_REFINEMENT_URL}user-tender-doc-refinements/${id}`,
			serializeQueryArgs: ({ endpointName }) => endpointName,
			transformResponse: (response: UserRefinementTenderPositions) =>
				normalizeTenderItemsData({
					items: response.userItemRefinements
						? tenderItemsAdapter.setAll(initialTenderItemsState, response.userItemRefinements)
						: { entities: {}, ids: [] },
					refinedCounter: response.refinedCounter,
					status: response.status,
					toCheckCounter: response.toCheckCounter
				})
		}),
		informUserRefinementThatPredictionsShouldBeMade: builder.mutation({
			query: ({ akt, tid }) => ({
				body: {
					tid
				},
				method: 'POST',
				url: `${USER_REFINEMENT_URL}user-tender-doc-refinements/{akt}?akt=${akt}`
			})
		})
	}),
	reducerPath: 'userRefinement',
	tagTypes: ['TenderItem']
});

export const {
	useAddUserRefinementToTenderItemMutation,
	useDeleteProductMutation,
	useDeleteSuppliersInBulkMutation,
	useEditOneTenderItemMutation,
	useEditProductInBulkMutation,
	useEditSuppliersInBulkMutation,
	useEditProductMutation,
	useEditTenderItemsInBulkMutation,
	useFetchRefinementForTenderItemsQuery,
	useFetchTenderItemsQuery,
	useInformUserRefinementThatPredictionsShouldBeMadeMutation,
	useLazyFetchExportVersionQuery,
	useFetchRefinementForTenderItemsProductQuery,
	useEditSupplierMutation
} = userRefinementApi;

export const selectRefinementForTenderItem = (theQueryArg: string) =>
	userRefinementApi.endpoints.fetchRefinementForTenderItems.select(theQueryArg);

export const selectTenderItems = (theQueryArg: string) =>
	userRefinementApi.endpoints.fetchTenderItems.select(theQueryArg);

export const selectProductById = createSelector(
	(state: RootState) => state,
	(state: RootState, tenderItemId: string, productId: string) => [
		selectRefinementForTenderItem(tenderItemId)(state),
		productId
	],
	(state: RootState, data: any) => {
		const [userRefinementData, productId] = data;
		return userRefinementData?.data?.userRefinedTenderItemProducts.find(
			(product: ProductForTableType) => product.id === productId
		);
	}
);

export const selectTenderItemById = createSelector(
	(state: RootState) => state,
	(state: RootState, contentUUID: string, tenderItemId: string) => [
		selectTenderItems(contentUUID)(state),
		tenderItemId
	],
	(state: RootState, tenderItemsData: any) => {
		const [tenderItems, tenderItemId] = tenderItemsData;
		return tenderItems.status === 'fulfilled'
			? (tenderItems.data as UserRefinementTree).data[tenderItemId]
			: undefined;
	}
);

export const selectSuppliers = createSelector(
	(state: RootState) => state,
	(state: RootState, contentUUID: string, tenderItemId: string) => [
		selectTenderItems(contentUUID)(state),
		tenderItemId
	],
	(state: RootState, tenderItemsData: any) => {
		const [tenderItems] = tenderItemsData;
		if (!tenderItems?.data?.data) {
			return undefined;
		}
		return getSuppliers(tenderItems?.data?.data);
	}
);

export const selectParentTenderItemById = createSelector(
	(state: RootState) => state,
	(state: RootState, contentUUID: string, tenderItemId: string) => [
		selectTenderItems(contentUUID)(state),
		tenderItemId
	],
	(state: RootState, tenderItemsData: any) => {
		const [tenderItems, tenderItemId] = tenderItemsData;
		const tenderItem =
			tenderItems.status === 'fulfilled'
				? (tenderItems.data as UserRefinementTree).data[tenderItemId]
				: undefined;
		return tenderItem?.parentUUID
			? (tenderItems.data as UserRefinementTree).data[tenderItem.parentUUID]
			: undefined;
	}
);

export const selectAllTenderItemChildrenIdsById = createSelector(
	(state: RootState) => state,
	(state: RootState, contentUUID: string, tenderItemId: string) => [
		selectTenderItems(contentUUID)(state),
		tenderItemId
	],
	(state: RootState, tenderItemsData: any) => {
		const [tenderItems, tenderItemId] = tenderItemsData;
		const allAffectedItemIds: string[] = [tenderItemId];
		const findIds = (ids: string[]) => {
			ids.forEach((id) => {
				allAffectedItemIds.push(id);
				if (tenderItems.data.data[id].children) {
					findIds(tenderItems.data.data[id].children);
				}
			});
		};
		if (tenderItems.data?.data) {
			findIds(tenderItems.data.data[tenderItemId].children);
		}
		return allAffectedItemIds.length ? allAffectedItemIds : [];
	}
);

export { userRefinementApi };

import memoize from 'lodash.memoize';

import type { SupplierProductPredictionType, TenderItem } from '../interfaces/TenderItem.types';

const getSuppliers = memoize((data: { [key: string]: TenderItem }) => {
	let suppliers: SupplierProductPredictionType[] = [];
	Object.entries(data).forEach(([, value]: any) => {
		if (!value.group) {
			value.userItemRefinementSuppliers?.forEach((supplier: any) => {
				if (supplier.supplierName.length) {
					const found = suppliers.find((item: any) => item.supplierName === supplier.supplierName);

					if (found) {
						if (value.status !== 'DECLINED')
							suppliers = suppliers.map((item: any) => {
								if (item.supplierName === supplier.supplierName) {
									return {
										...item,
										positions: [...item.positions, value.position]
									};
								}
								return item;
							});
					} else {
						suppliers.push({
							...supplier,
							positions: value.status !== 'DECLINED' ? [value.position] : []
						});
					}
				}
			});
		}
	});

	return suppliers;
});

export default getSuppliers;
